import React, { useEffect, useContext } from "react"

import { categories } from "../../static/assets/js/categories"
import LayoutContext from "../components/layout/layout.context"
import HistoriesContext from "../context/histories.context"
import useHistoriesData from "../hooks/useHistoriesData"
import Home from "../components/template/home/Home"
import CategoryHeader from "../components/modules/categoryHeader/CategoryHeader"

const Familia = () => {
  const context = useContext(LayoutContext)
  const historiesData = useHistoriesData()
  const { familia } = categories

  useEffect(() => {
    context.setActiveCategory(4)
    return () => {
      context.setActiveCategory(undefined)
    }
  }, [])

  return (
    <HistoriesContext.Provider value={historiesData}>
      <div className="wrapper">
        <CategoryHeader title={familia.title} desc={familia.desc} />
        <Home category="familia" hideHeader />
      </div>
    </HistoriesContext.Provider>
  )
}

export default Familia
